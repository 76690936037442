const WEBSITE_URL = process.env.WEBSITE_URL || `https://${process.env.ROOT_DOMAIN}`;

const LINKS = {
  product_docs: {
    root: `${WEBSITE_URL}/docs/`,
    connections: `${WEBSITE_URL}/docs/connections`,
    connection_rules: `${WEBSITE_URL}/docs/connections#connection-rules`,
    inbuilt_source_support: `${WEBSITE_URL}/docs/authentication#source-authentication`,
    cli: `${WEBSITE_URL}/docs/using-the-cli`,
    cli_install: `${WEBSITE_URL}/docs/using-the-cli#getting-started-with-the-cli`,
    filters: `${WEBSITE_URL}/docs/filters`,
    transformations: `${WEBSITE_URL}/docs/transformations`,
    transformation_handler_function: `${WEBSITE_URL}/docs/transformations#handler-function`,
    transformation_environment_variables: `${WEBSITE_URL}/docs/transformations#environment-variables`,
    transformation_troubleshooting: `${WEBSITE_URL}/docs/transformations#troubleshoot-a-transformation`,
    retries: `${WEBSITE_URL}/docs/retries`,
    sources: `${WEBSITE_URL}/docs/sources`,
    sources_add_auth: `${WEBSITE_URL}/docs/sources#add-source-authentication`,
    sources_customize_response: `${WEBSITE_URL}/docs/sources#custom-responses#custom-responses`,
    sources_custom_methods: `${WEBSITE_URL}/docs/sources#create-a-source#custom-methods`,
    sources_enable: `${WEBSITE_URL}/docs/sources#disable-a-source#enable-a-source`,
    sources_disable: `${WEBSITE_URL}/docs/sources#disable-a-source#disable-a-source`,
    sources_delete: `${WEBSITE_URL}/docs/sources#delete-a-source`,
    destinations: `${WEBSITE_URL}/docs/destinations`,
    destinations_advanced_edit: `${WEBSITE_URL}/docs/destinations#edit-destination-advanced-configuration`,
    destinations_auth_strategy: `${WEBSITE_URL}/docs/destinations#customize-destination-authentication-strategy`,
    destinations_max_delivery_rate: `${WEBSITE_URL}/docs/destinations#set-a-max-delivery-rate-`,
    destinations_enable: `${WEBSITE_URL}/docs/destinations#enable-a-destination`,
    destinations_disable: `${WEBSITE_URL}/docs/destinations#disable-a-destination`,
    destinations_delete: `${WEBSITE_URL}/docs/destinations#delete-a-destination`,
    update_connection: `${WEBSITE_URL}/docs/edit-a-connection`,
    connection_delay: `${WEBSITE_URL}/docs/connections#delay-rule`,
    connection_pause: `${WEBSITE_URL}/docs/connections#pause-a-connection#pause-a-connection`,
    connection_enable: `${WEBSITE_URL}/docs/connections#enable-a-connection`,
    connection_disable: `${WEBSITE_URL}/docs/connections#disable-a-connection`,
    connection_delete: `${WEBSITE_URL}/docs/connections#delete-a-connection`,
    bulk_retry: `${WEBSITE_URL}/docs/manually-retry-events#retry-many-events`,
    signature_verification: `${WEBSITE_URL}/docs/signature-verification#verify-the-hookdeck-signature`,
    request_retries: `${WEBSITE_URL}/docs/requests#retrying-a-request`,
    ignored_events: `${WEBSITE_URL}/docs/requests#ignored-events`,
    metrics: `${WEBSITE_URL}/docs/metrics`,
    issues: `${WEBSITE_URL}/docs/issues`,
    issue_triggers_delivery: `${WEBSITE_URL}/docs/issue-triggers#delivery-issues`,
    issue_triggers_transformation: `${WEBSITE_URL}/docs/issue-triggers#transformation-issues`,
    issue_triggers_backpressure: `${WEBSITE_URL}/docs/issue-triggers#backpressure-issues`,
    requests: `${WEBSITE_URL}/docs/requests`,
    events: `${WEBSITE_URL}/docs/events`,
    rules: `${WEBSITE_URL}/docs/rules`,
    bookmarks: `${WEBSITE_URL}/docs/bookmarks`,
  },
  guides: `${WEBSITE_URL}/docs/guides`,
  landing: `${WEBSITE_URL}`,
  pricing: `${WEBSITE_URL}/pricing`,
  privacy: `${WEBSITE_URL}/privacy`,
  terms: `${WEBSITE_URL}/terms`,
  docs: `${WEBSITE_URL}/docs`,
  api_ref: {
    root: `${WEBSITE_URL}/docs/api`,
    sources: `${WEBSITE_URL}/docs/api#sources`,
    destinations: `${WEBSITE_URL}/docs/api#destinations`,
    connections: `${WEBSITE_URL}/docs/api#connections`,
    rule: `${WEBSITE_URL}/docs/api#rule`,
    events: `${WEBSITE_URL}/docs/api#events`,
    requests: `${WEBSITE_URL}/docs/api#requests`,
  },
  go_sdk: 'https://github.com/hookdeck/hookdeck-go-sdk',
  typescript_sdk: 'https://github.com/hookdeck/hookdeck-typescript-sdk',
  terraform_provider: 'https://registry.terraform.io/providers/hookdeck/hookdeck/latest/docs',
  changelog: 'https://changelog.hookdeck.com',
  roadmap: 'https://roadmap.hookdeck.com',
  mock_api: 'https://mock.hookdeck.com',
  community:
    'https://join.slack.com/t/hookdeckdevelopers/shared_invite/zt-yw7hlyzp-EQuO3QvdiBlH9Tz2KZg5MQ',
} as const;

export default LINKS;

import { useField, useFormikContext } from 'formik';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import Button, { ClickableArea } from '../../base/Button';
import Icon from '../../base/Icon';
import Text from '../../base/Text';
import { Div, StyledUtilsProps } from '../../helpers/StyledUtils';
import { Variant } from './Input/Input';

interface Props extends StyledUtilsProps {
  label?: string;
  inline_label?: string;
  variant?: Variant;
  name: string;
  help?: string;
  type?: string;
  disabled?: boolean;
  default_value?: string;
  placeholder?: string;
  auto_complete?: string;
  maxlength?: number;
  minlength?: number;
  monospace?: boolean;
  max?: number;
  min?: number;
  required?: boolean;
  focus?: boolean;
  show_error?: boolean;
  format?: (v: string) => string;
  validate?: (string) => Promise<string | void> | string | undefined;
  onBlur?: () => void;
}

const StyledField = styled(Div)<{
  has_reveal_password?: boolean;
  large?: boolean;
  variant?: Variant;
}>(
  ({ theme, variant, has_reveal_password }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${has_reveal_password &&
    css`
      input {
        padding-right: ${theme.spacing(10)} !important;
      }
    `};

    &:last-of-type {
      margin-bottom: 0 !important;
    }

    ${variant === 'card' &&
    css`
      &:focus-within {
        outline: 2px solid ${theme.colors.outline.focus.primary};
        border-radius: ${theme.radius.normal};
      }

      &:focus-within label {
        border: 1px solid ${theme.colors.outline.focus.primary};
        border-bottom: none;
      }

      &:focus-within input {
        outline: none !important;
      }

      label {
        margin: 0;
        border: 1px solid ${theme.colors.outline.neutral};
        border-bottom: none;
        -webkit-box-shadow: 0px 2px 0px -1px ${theme.colors.outline.neutral};
        box-shadow: 0px 2px 0px -1px ${theme.colors.outline.neutral};
        border-radius: ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0 0;
        padding: ${theme.spacing(1)} ${theme.spacing(3)};
        background-color: ${theme.colors.surface.base.variant_surface_2};
        width: 100%;
        z-index: 1;
        margin-bottom: -1px;
      }

      input {
        border-radius: 0 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset};
      }
    `}
  `,
);

const StyledInputWrapper = styled.div<{
  has_inline_label: boolean;
  monospace: boolean;
  password?: boolean;
}>(
  ({ theme, monospace, has_inline_label }) => css`
    position: relative;
    input {
      box-sizing: border-box;
      width: 100%;
      min-width: 100%;

      &:focus {
        outline: 1px solid ${theme.colors.outline.focus.primary};
        border-color: ${theme.colors.outline.focus.primary};
      }
    }

    input[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      padding-right: ${theme.spacing(6)};
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    ${monospace &&
    css`
      input {
        font-family:
          JetBrains Mono,
          monospace;
      }
    `}
    ${has_inline_label &&
    css`
      display: flex;
      div:first-child {
        border: ${theme.border};
        border-radius: ${`${theme.radius.normal} 0 0 ${theme.radius.normal}`};
        padding: 0 ${theme.spacing(3)};
        background-color: ${theme.colors.surface.base.disabled};
        border-right: ${theme.border};
        display: inline-flex;
        align-items: center;
        border-right: none;
      }
      div:last-child {
        flex-grow: 1;
      }
      input {
        border-radius: 0 ${theme.radius.normal} ${theme.radius.normal} 0;
      }
    `}
  `,
);

const StyledQuantityButtons = styled.div`
  position: absolute;
  right: 4px;
  top: calc(50% + 0.5px);
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const StyledQuantityButton = styled(ClickableArea)(
  ({ theme }) => css`
    padding: 0;
    border-radius: ${theme.radius.xsmall};
    background-color: ${theme.colors.surface.container.neutral};
    height: 10px;
    &:hover {
      background-color: ${theme.colors.surface.container.hover.neutral};
    }
    &:active {
      background-color: ${theme.colors.surface.base.active.neutral};
    }
    span {
      transform: translateY(-3px);
    }
  `,
);
const StyledInput = styled(Div)<{ error: boolean; variant?: Variant }>(
  ({ theme, error, variant }) => css`
    position: relative;
    display: flex;

    ${error &&
    css`
      input:not(:focus) {
        ::placeholder {
          color: ${theme.colors.on.neutral.danger};
        }
        border-color: ${theme.colors.on.hue_container.danger};
        background-color: ${theme.colors.surface.container.danger};
      }
    `}

    input {
      font-size: ${theme.pxToRem(13)};
      line-height: ${theme.pxToRem(20)};
      padding: ${theme.spacing(1.25)} ${theme.spacing(variant === 'card' ? 3 : 2)};
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.04) inset;
      &:disabled {
        background-color: ${theme.colors.surface.base.disabled};
        box-shadow: none;
      }
    }
  `,
);

const StyledRevealPasswordButton = styled(Button)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)};
  top: 50%;
  transform: translateY(-50%);
`;

const RevealPasswordButton = ({ onClick, enabled }: { onClick: () => void; enabled }) => {
  return (
    <StyledRevealPasswordButton
      minimal
      tabIndex={-1}
      m={{ r: -0.5 }}
      p={{ x: 1, y: 0.5 }}
      label="Show password in cleartext"
      icon={enabled ? 'dismiss' : 'view'}
      onClick={onClick}
    />
  );
};

const useRevealPassword = (type = 'text') => {
  const [input_type, setType] = useState('password');
  const toggleInputType = () => (input_type === 'password' ? setType('text') : setType('password'));

  if (type !== 'password') {
    // only do this if the type is password
    return {
      input_type: type,
      toggleInputType,
      show_clear: false,
      show_password_button: false,
    };
  } else {
    return {
      input_type,
      toggleInputType,
      show_clear: input_type === 'text',
      show_password_button: true,
    };
  }
};

const TextInput: React.FC<Props> = ({
  type,
  label,
  inline_label,
  variant,
  name,
  default_value,
  placeholder,
  auto_complete,
  required,
  max,
  min,
  maxlength,
  minlength,
  help,
  monospace,
  disabled: _disabled,
  focus,
  show_error = true,
  format,
  validate,
  onBlur,
  ...other_props
}) => {
  const formatValue = (v: string): string => {
    return format && v ? format(v) : v;
  };

  const validateRequired = (value: string) => {
    if (value?.length < 1 && required) return 'Required';
  };

  const { submitCount } = useFormikContext();
  const [field, { value, error, touched }, { setValue, setTouched }] = useField({
    name,
    validate: validate || validateRequired,
  });
  const [ignore_default, setIgnoreDefault] = useState(field.value && !touched);
  const last_default_value = useRef<string>(formatValue(default_value || ''));
  const ref = useRef<HTMLInputElement | null>(null);

  const disabled = usePermissionAwareDisabled(_disabled);

  const { input_type, show_clear, show_password_button, toggleInputType } = useRevealPassword(type);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (default_value !== undefined && !ignore_default) {
      setValue(formatValue(default_value));
    }
  }, [default_value, ignore_default]);

  useEffect(() => {
    if (field.value === '' && !ignore_default) {
      setIgnoreDefault(false);
    }
  }, [field.value, default_value]);

  useEffect(() => {
    if (
      field.value === '' &&
      ignore_default &&
      last_default_value.current !== formatValue(default_value || '')
    ) {
      setIgnoreDefault(false);
      setValue(formatValue(default_value || ''));
    }
  }, [field.value, default_value, ignore_default, last_default_value]);

  const handleIncrement = () => {
    let new_value = 0;
    if (value < min! || value === undefined || value === '' || isNaN(value)) {
      new_value = min!;
    } else {
      new_value = parseInt(value) + 1;
    }
    setValue(new_value > max! ? max : new_value);
    setTouched(true);
    if (ref.current && document.activeElement !== ref.current) {
      ref.current.focus();
    }
  };

  const handleDecrement = () => {
    let new_value = 0;
    if (max! && value > max!) {
      new_value = max!;
    } else {
      new_value = value === '' || value === undefined || isNaN(value) ? min! : parseInt(value) - 1;
    }
    setValue(new_value < min! ? min! : new_value);
    setTouched(true);
    if (ref.current && document.activeElement !== ref.current) {
      ref.current.focus();
    }
  };

  const handlePreventDeFocus = (e: MouseEvent<HTMLElement>) => {
    if (document.activeElement === ref.current) {
      e.preventDefault();
    }
  };

  // Prevent the component from being considered uncontrolled because no value is set yet
  if (field.value === undefined) {
    field.value = '';
  }

  const has_error = show_error && (touched || submitCount > 0) && !!error;

  return (
    <Div m={{ bottom: 4 }} {...other_props}>
      <StyledField large variant={variant} has_reveal_password={show_password_button}>
        {label && (
          <Div flex={{ justify: 'space-between', align: 'center' }}>
            <label htmlFor={name}>
              <Text subtitle size={variant === 'card' ? 'xs' : 's'}>
                {label}
                {required && (
                  <Text as="span" danger>
                    *
                  </Text>
                )}
              </Text>
            </label>
          </Div>
        )}
        <StyledInputWrapper
          has_inline_label={!!inline_label}
          monospace={monospace ?? false}
          password={show_password_button}>
          {inline_label && <Text>{inline_label}</Text>}
          <StyledInput error={has_error} variant={variant}>
            <input
              {...field}
              ref={ref}
              onChange={(e) => {
                setIgnoreDefault(true);
                e.target.value = formatValue(e.target.value);
                field.onChange(e);
                last_default_value.current = formatValue(default_value || '');
              }}
              disabled={disabled}
              type={input_type}
              placeholder={default_value || formatValue(placeholder || '')}
              autoComplete={auto_complete}
              required={required}
              max={max}
              min={min}
              onBlur={() => {
                setTouched(true);
                onBlur && onBlur();
              }}
              maxLength={maxlength}
              minLength={minlength}
            />
            {show_password_button && (
              <RevealPasswordButton onClick={toggleInputType} enabled={show_clear} />
            )}
            {type === 'number' && (
              <StyledQuantityButtons>
                <StyledQuantityButton
                  disabled={disabled}
                  onClick={handleIncrement}
                  onMouseDown={handlePreventDeFocus}>
                  <Icon icon={'arrow_drop_up'} small />
                </StyledQuantityButton>
                <StyledQuantityButton
                  disabled={disabled}
                  onClick={handleDecrement}
                  onMouseDown={handlePreventDeFocus}>
                  <Icon icon={'arrow_drop_down'} small />
                </StyledQuantityButton>
              </StyledQuantityButtons>
            )}
          </StyledInput>
        </StyledInputWrapper>
        {has_error && (
          <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
            <Icon icon="info" left={1} />
            {error}
          </Text>
        )}
        {help && !has_error && (
          <Text m={{ t: 1, b: 0 }} size="s" as="p" muted>
            <Icon icon="info" left={1} />
            {help}
          </Text>
        )}
      </StyledField>
    </Div>
  );
};

export default TextInput;
